export default {

	computed: {

		$path: function() {

			return this.$route.path

		},

		$state: function() {

			return this.$store.getters['data']

		}

	}

}