<template>

<div class="more-wrapper">

	<router-link :to="to" class="more">{{ text }}</router-link>

</div>

</template>

<script>

export default {

	props: ['to', 'text']

}

</script>

<style scoped>

.more-wrapper {
	display: flex;
	justify-content: flex-end;
}

.more {
	text-align: right;
	padding-left: 30px;
	font-size: 14px;
	padding-bottom: 5px;
	font-weight: 700;
	color: #112A45;
	border-bottom: 2px solid #BCD9CD;
	transition: padding-left 100ms linear;
}

.is-desktop .more:hover {
	padding-left: 40px;
}

</style>
